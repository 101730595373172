import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Call = () => {

  return (
    <div>
      <p className="mt-2">
        Funnet en feil? Gjennom dette skjemaet kan du ta kontakt med oss.
      </p>
      <form className="mt-4" action="https://formspree.io/f/mrgrgaek" method="POST">
        <div>
          <lable for="email" className="q-input-label">Epost-adresse</lable>
          <input className="q-input" style={{width: '300px'}} required type="text" name="email" id="email"></input>
        </div>
        <div className="mt-2">
          <lable for="melding" className="q-input-label">Melding</lable>
          <textarea className="q-input w-100" style={{height: '100px'}} required name="melding" id="melding"></textarea>
        </div>
        <div className="mt-3">
          <button className="btn-primary" type="submit">Send inn</button>
        </div>
      </form>
    </div>
  );
};

export default Call;
